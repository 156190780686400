import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { DataExtractionService } from "src/app/service/DataExtractionService/data-extraction.service";
import {
  FetchFileById,
  FetchFileByIdFailure,
  FetchFileByIdSuccess,
  GetConditions,
  GetConditionsFailure,
  GetConditionsSuccess,
  GetDocumentTypeList,
  GetDocumentTypeListFailure,
  GetDocumentTypeListSuccess,
} from "./data-extraction.actions";

@Injectable()
export class DataExtractionEffects {
  constructor(
    private actions$: Actions,
    private dataExtractionService: DataExtractionService
  ) {}

  // Effect for loading document type list
  loadDocumentTypeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDocumentTypeList),
      mergeMap((action) =>
        this.dataExtractionService.getDocumentTypeList(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetDocumentTypeListSuccess({ data: response.data });
            } else {
              return GetDocumentTypeListFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) =>
            of(
              GetDocumentTypeListFailure({
                errors: error,
                message: error.message || "Failed to load document types",
              })
            )
          )
        )
      )
    )
  );

  loadConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetConditions),
      mergeMap((action) =>
        this.dataExtractionService.getConditionsForLoanId(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetConditionsSuccess({ data: response.data });
            } else {
              return GetConditionsFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) =>
            of(
              GetConditionsFailure({
                errors: error,
                message: error.message || "Failed to load conditions",
              })
            )
          )
        )
      )
    )
  );

  fetchFileById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchFileById),
      mergeMap((action) =>
        this.dataExtractionService.fetchFileById(action.loanId, action.fileId).pipe(
          map((response) => {
            if (response.success) {
              return FetchFileByIdSuccess({ data: response.data });
            } else {
              return FetchFileByIdFailure({
                message: response.message,
              });
            }
          }),
          catchError((error) =>
            of(
              FetchFileByIdFailure({
                message: error.message || "Failed to fetch file by ID",
              })
            )
          )
        )
      )
    )
  );
}
