import { createReducer, on } from "@ngrx/store";
import {
  FetchFileById,
  FetchFileByIdFailure,
  FetchFileByIdSuccess,
  GetConditions,
  GetConditionsFailure,
  GetConditionsSuccess,
  GetDocumentTypeList,
  GetDocumentTypeListFailure,
  GetDocumentTypeListSuccess,
} from "./data-extraction.actions";
import { initialDataExtractionDataResponse } from "./data-extraction.selectors";

export const dataExtractionReducer = createReducer(
  initialDataExtractionDataResponse,
  on(GetDocumentTypeList, (state) => ({
    ...state,
    documentsList: {
      ...state.documentsList,
      data: [],
      errorMessage: undefined,
    },
    isLoading: state.isLoading + 1,
  })),
  on(GetDocumentTypeListSuccess, (state, { data }) => ({
    ...state,
    documentsList: {
      ...state.documentsList,
      data,
      errorMessage: undefined,
    },
    isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
  })),
  on(GetDocumentTypeListFailure, (state, { message }) => ({
    ...state,
    documentsList: {
      ...state.documentsList,
      data: [],
      errorMessage: message,
    },
    isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
  })),

  on(GetConditions, (state) => ({
    ...state,
    conditionsData: {
      ...state.conditionsData,
      data: [],
      errorMessage: undefined,
    },
    isLoading: state.isLoading + 1,
  })),
  on(GetConditionsSuccess, (state, { data }) => ({
    ...state,
    conditionsData: {
      ...state.conditionsData,
      data,
      errorMessage: undefined,
    },
    isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
  })),
  on(GetConditionsFailure, (state, { message }) => ({
    ...state,
    conditionsData: {
      ...state.conditionsData,
      data: [],
      errorMessage: message,
    },
    isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
  })),

  on(FetchFileById, (state) => ({
    ...state,
    fileData: {
      ...state.fileData,
      data: "",
      errorMessage: undefined,
    },
    isLoading: state.isLoading + 1,
  })),
  on(FetchFileByIdSuccess, (state, { data }) => ({
    ...state,
    fileData: {
      ...state.fileData,
      data: data,
      errorMessage: undefined,
    },
    isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
  })),
  on(FetchFileByIdFailure, (state, { message }) => ({
    ...state,
    fileData: {
      ...state.fileData,
      data: "",
      errorMessage: message,
    },
    isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
  }))
);
